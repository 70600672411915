import NavbarEnum from '../enumerators/navbar.enum'

export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
        NavbarComponent.lang()
    }

    static sticky() {
        let lastScrollTop = 0
        let stateOnTop = true

        $(window).on('load scroll', function () {
            const offset = 100 // Navbar default height
            const offsetOnScroll = 100 // Navbar height on scroll
            const navbar = $(NavbarEnum.NAVBAR)
            const scrollTop = $(this).scrollTop()
            const hasBanner = false

            // Hidden on scroll comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $(NavbarEnum.BODY).addClass('header-is-hidden')
            } else {
                $(NavbarEnum.BODY).removeClass('header-is-hidden')
            }

            lastScrollTop = scrollTop

            // On top/on scroll switch
            if (scrollTop > offset && stateOnTop === true) {
                stateOnTop = false
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')
            } else if (scrollTop < offsetOnScroll && stateOnTop === false) {
                stateOnTop = true
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')
            }
        })
    }

    static burger() {
        //Open/Close menu
        $(`${NavbarEnum.BURGER_MENU_TRIGGER}, ${NavbarEnum.MENU_TRIGGER}`).on(
            'click',
            function (event) {
                if ($(this).is(NavbarEnum.MENU_TRIGGER)) {
                    if (event.target !== this) return
                }

                $(NavbarEnum.BODY).toggleClass('menu-open')

                if ($(NavbarEnum.BODY).hasClass('menu-open')) {
                    $(NavbarEnum.BURGER_MENU_MORE).each(function () {
                        if (
                            $(this).hasClass('has-submenu') &&
                            window.matchMedia('(min-width: 1001px)').matches
                        ) {
                            $(this).addClass('active')
                            $(this).next(NavbarEnum.BURGER_SUBMENU).addClass('submenu-open')
                            return false
                        }
                    })
                } else {
                    $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
                    $(NavbarEnum.BURGER_MENU_MORE).removeClass('active')
                }

                //Hide ecomode
                $(NavbarEnum.ECOMODE).addClass('eco-bar--hidden')
            },
        )

        //Open/Close submenu
        $(NavbarEnum.BURGER_MENU_MORE).on('click', function (e) {
            e.preventDefault()
            $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
            $(NavbarEnum.BURGER_MENU_MORE).removeClass('active')
            $(this).addClass('active')
            $(this).next(NavbarEnum.BURGER_SUBMENU).addClass('submenu-open')
        })

        $(NavbarEnum.BURGER_MENU_PREV).on('click', (e) => {
            e.preventDefault()
            $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
        })

        $(document).on('keyup', (e) => {
            if (e.keyCode === 27) {
                $(NavbarEnum.BODY).removeClass('menu-open')
                $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
                $(NavbarEnum.BURGER_MENU_MORE).removeClass('active')
            }
        })
    }

    static lang() {
        const switcher = '[data-trigger="lang-switcher"]'
        const list = '[data-id="lang-switcher-list"]'

        $(switcher).on('click', () => {
            $(list).toggleClass('lang-switch__list--open')
        })

        $(window).on('load scroll', () => {
            if ($('body').hasClass('header-is-hidden')) {
                $(list).removeClass('lang-switch__list--open')
            }
        })

        $(document).on('click', (e) => {
            if (!$(switcher).is(e.target) && $(switcher).has(e.target).length === 0) {
                $(list).removeClass('lang-switch__list--open')
            }
        })
    }
}
